


import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { ExportToCsv } from 'export-to-csv';
import { Image } from 'src/app/class/image/image';
import { User } from 'src/app/class/user/user';
import { Clinic } from 'src/app/class/clinic/clinic';
import { AlertViewService } from 'src/app/services/alert-view.service';
import { SELECCTIONS_EVENT, SELECCTIONS_PATIENT } from 'src/app/class/selection/selection';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage-angular';
import { EChartsOption } from 'echarts';


import * as firebase from 'firebase/app';
import { Userinfo } from 'src/app/services/userinfo';

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AngularFirestore } from '@angular/fire/firestore';
import { take } from 'rxjs/operators';
import { getLangFromComponent } from 'src/app/lang/logic';
import { ComponentLabel } from 'src/app/lang/dictionary';
@Component({
  selector: 'app-item-detail-chart',
  templateUrl: './item-detail-chart.component.html',
  styleUrls: ['./item-detail-chart.component.css']
})
export class ItemDetailChartComponent implements OnInit {
  public lang = getLangFromComponent(ComponentLabel.itemdetailchart);

  titles = ['熱中症リスク', '心拍モニタリング', '眠気モニタリング', '疲労モニタリング', '気象モニタリング', '自律神経モニタリング', '運転モニタリング', '感情モニタリング'];

  done = ['Get up', 'Brush teeth', 'Take a shower', 'Check e-mail', 'Walk dog', 'Walk dog', 'Walk dog', 'Walk dog'];


  upColor = '#ec0000';
  upBorderColor = '#8A0000';
  downColor = '#00da3c';
  downBorderColor = '#008F28';
  // Each item: open，close，lowest，highest
  data0 = this.splitData([

  ]);

  data1 = {
    categoryData: [],
    values: []
  };



  splitData(rawData) {
    console.log("splitDataaaaaaaaaaaaaaaaaaaaaaaa")
    console.log(rawData)
    const categoryData = [];
    const values = [];
    for (var i = 0; i < rawData.length; i++) {
      categoryData.push(rawData[i].splice(0, 1)[0]);
      values.push(rawData[i]);
    }

    console.log({
      categoryData: categoryData,
      values: values
    })



    return {
      categoryData: categoryData,
      values: values
    };
  }

  calculateMA(dayCount) {
    console.log("calclateMAAAAAAAAAAAAAAAA")
    console.log(dayCount);
    var result = [];
    for (var i = 0, len = this.data0.values.length; i < len; i++) {
      if (i < dayCount) {
        result.push('-');
        continue;
      }
      var sum = 0;

      result.push(this.data0.values[i][0]);
    }
    console.log(result);

    return result;
  }

  calculateMA1(): (number | null)[] {
    return this.data1.values.map(x => x);
  }

  calcCenterLine() {
    return this.data1.values.map(item => item === null ? '-' : item);

  }

  calcAVG() {
    console.log("calcAVGGGGGGGGGGG")

    return this.data1.values.map(item => this.averageValue);
  }





  median = function (arr) {
    // console.log("mediannnnnnnn")
    // console.log(arr)
    var half = (arr.length / 2) | 0;
    var temp = arr.sort();

    if (temp.length % 2) {
      return temp[half];
    }

    return (temp[half - 1] + temp[half]) / 2;
  };




  chartOptionB: EChartsOption = {
    title: {
      text: this.lang.show('心拍数の推移'),
      left: 0
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      }
    },
    legend: {
      data: this.lang.showArray(['心拍数', '平均値', '中央値'])
    },
    grid: {
      left: '10%',
      right: '10%',
      bottom: '15%',
    },
    xAxis: {
      type: 'category',
      data: this.data0.categoryData,
      boundaryGap: false,
      axisLine: { onZero: false },
      splitLine: { show: false },
      min: 'dataMin',
      max: 'dataMax'
    },
    yAxis: {
      scale: true,
      splitArea: {
        show: true
      }
    },
    dataZoom: [
      {
        type: 'inside',
        start: 50,
        end: 100
      },
      {
        show: true,
        type: 'slider',
        top: '90%',
        start: 50,
        end: 100
      }
    ],
    series: [

      {
        name: this.lang.show('心拍数'),
        type: 'line',
        data: this.calculateMA(1),
        smooth: true,
        lineStyle: {
          opacity: 0.3
        }
      },
      {
        name: this.lang.show('平均値'),
        type: 'line',
        data: this.calculateMA(2),
        smooth: true,
        lineStyle: {
          opacity: 0.3
        }
      },
      {
        name: this.lang.show('中央値'),
        type: 'line',
        data: this.calculateMA(3),
        smooth: true,
        lineStyle: {
          opacity: 0.8
        },
        itemStyle: {
          // Color in emphasis state.
          color: '#e63757'
        }
      }
    ]
  };






  public currentUser: User;

  shiborikomiWord = "";
  showOnlyUnread = false;
  averageValue = 80;

  macad = "";
  public device: Observable<any[]>;

  constructor(
    private alertViewService: AlertViewService,
    private localStorage: Storage,
    private userInfo: Userinfo,
    private route: ActivatedRoute,
    private db: AngularFirestore,
  ) {
    this.macad = (this.route.snapshot.paramMap.get('id'));
    this.userInfo.getWeatherInfo();
    this.alertViewService.showLoading();

    this.getChartData();
  }


  formatDate(date, format) {
    format = format.replace(/yyyy/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3));
    return format;
  };

  async getChartData() {
    const date = new Date();
    const start = new Date(date.setHours(0, 0, 0, 0));
    const end = new Date(date.setHours(23, 59, 59, 0));
    const dayMinutes = [...Array(24 * 60)].map((_, index) => index);
    const format = "HH:mm"

    const addMinutes = (date: Date, minutes: number): Date => {
      const result = new Date(date);
      result.setTime(result.getTime() + minutes * 60000); // 1分 = 60000ミリ秒
      return result;
    }

    (await firebase.default.firestore().collection(this.macad + "-total").orderBy('time', 'desc').limit(1).get()).docs
      .forEach(doc => this.averageValue = doc.data().averageOfAveragePR);

    this.data1 = {
      categoryData: dayMinutes.map(minutes => this.formatDate(addMinutes(start, minutes), format)),
      values: dayMinutes.map(x => null)
    };

    const insertRecords = (await firebase.default.firestore().collection(this.macad).where('timestamp', '>', start)
      .where('timestamp', '<', end).orderBy('timestamp', 'asc').get()).docs
      .map(doc => doc.data())
      .map(data => {
        return {
          time: this.formatDate(data.timestamp.toDate(), format),
          value: data.PR
        }

        /*
        this.data1.categoryData.push(this.formatDate(new Date(data.timestamp.seconds * 1000), format));
        this.data1.values.push([data.PR]);
        */
      });

    insertRecords.forEach(data => {
      const index = this.data1.categoryData.indexOf(data.time)
      if (index >= 0) {
        this.data1.categoryData[index] = data.time
        this.data1.values[index] = data.value
      }
    })

    this.setEChart();
    this.alertViewService.dismiss();
  }


  setEChart() {

    this.chartOptionB = {
      title: {
        text: this.lang.show('心拍数の推移'),
        left: 0
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
      legend: {
        data: this.lang.showArray(['心拍数', '平均値', '中央値'])
      },
      grid: {
        left: '10%',
        right: '10%',
        bottom: '15%'
      },
      xAxis: {
        type: 'category',
        data: this.data1.categoryData,
        boundaryGap: false,
        axisLine: { onZero: false },
        splitLine: { show: false },
        min: 'dataMin',
        max: 'dataMax'
      },
      yAxis: {
        scale: true,
        splitArea: {
          show: true
        }
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          show: true,
          type: 'slider',
          top: '90%',
          start: 0,
          end: 100
        }
      ],
      series: [

        {
          name: this.lang.show('心拍数'),
          type: 'line',
          data: this.calculateMA1(),
          smooth: true,
          lineStyle: {
            opacity: 0.3
          }
        },
        {
          name: this.lang.show('平均値'),
          type: 'line',
          data: this.calcAVG(),
          smooth: true,
          lineStyle: {
            opacity: 0.3
          }
        },
        {
          name: this.lang.show('中央値'),
          type: 'line',
          data: this.calcCenterLine(),
          smooth: true,
          lineStyle: {
            opacity: 0.8
          },
          itemStyle: {
            // Color in emphasis state.
            color: '#e63757'
          }
        }
      ]
    };

  }



  ngOnInit() {
    console.log('ngOnInitです');



    this.device = this.db
      .collection<any>(this.macad, ref => { return ref.orderBy('timestamp', 'desc').limit(1); })
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(action => {
          var data = action.payload.doc.data();

          data["time"] = data.timestamp.toDate();
          data["timeString"] = data.timestamp.toDate().toLocaleString(this.lang.show('ja'));



          // console.log(data);
          return data;
        })));

    this.device.subscribe();

  }

  getWBGT(weather, isStatus) {

    var now = new Date();
    var indexNum = 0;

    if (weather == undefined) return this.lang.show("判定なし");

    weather["time"].forEach((item, index) => {
      var dateA = new Date(item);
      var diff = dateA.valueOf() - now.valueOf();
      if ((diff < (1000 * 60 * 30)) && (diff > (-1000 * 60 * 30))) {
        indexNum = index;
      }
    });

    var humidity = Math.round(weather["relativehumidity_2m"][indexNum] / 10) * 10;
    var temperature = parseInt(weather["temperature_2m"][indexNum]);


    var WBGTTable = {
      2040: 29,
      2039: 28,
      2038: 28,
      2037: 27,
      2036: 26,
      2035: 25,
      2034: 25,
      2033: 24,
      2032: 23,
      2031: 22,
      2030: 21,
      2029: 21,
      2028: 20,
      2027: 19,
      2026: 18,
      2025: 18,
      2024: 17,
      2023: 16,
      2022: 15,
      2021: 15,
      2540: 30,
      2539: 29,
      2538: 28,
      2537: 28,
      2536: 27,
      2535: 26,
      2534: 25,
      2533: 25,
      2532: 24,
      2531: 23,
      2530: 22,
      2529: 21,
      2528: 21,
      2527: 20,
      2526: 19,
      2525: 18,
      2524: 18,
      2523: 17,
      2522: 16,
      2521: 15,
      3040: 31,
      3039: 30,
      3038: 29,
      3037: 29,
      3036: 28,
      3035: 27,
      3034: 26,
      3033: 25,
      3032: 25,
      3031: 24,
      3030: 23,
      3029: 22,
      3028: 21,
      3027: 21,
      3026: 20,
      3025: 19,
      3024: 18,
      3023: 17,
      3022: 17,
      3021: 16,
      3540: 32,
      3539: 31,
      3538: 30,
      3537: 29,
      3536: 29,
      3535: 28,
      3534: 27,
      3533: 26,
      3532: 25,
      3531: 24,
      3530: 24,
      3529: 23,
      3528: 22,
      3527: 21,
      3526: 20,
      3525: 20,
      3524: 19,
      3523: 18,
      3522: 17,
      3521: 16,
      4040: 33,
      4039: 32,
      4038: 31,
      4037: 30,
      4036: 29,
      4035: 29,
      4034: 28,
      4033: 27,
      4032: 26,
      4031: 25,
      4030: 24,
      4029: 24,
      4028: 23,
      4027: 22,
      4026: 21,
      4025: 20,
      4024: 19,
      4023: 19,
      4022: 18,
      4021: 17,
      4540: 34,
      4539: 33,
      4538: 32,
      4537: 31,
      4536: 30,
      4535: 29,
      4534: 29,
      4533: 28,
      4532: 27,
      4531: 26,
      4530: 25,
      4529: 24,
      4528: 23,
      4527: 23,
      4526: 22,
      4525: 21,
      4524: 20,
      4523: 19,
      4522: 18,
      4521: 17,
      5040: 35,
      5039: 34,
      5038: 33,
      5037: 32,
      5036: 31,
      5035: 30,
      5034: 29,
      5033: 28,
      5032: 28,
      5031: 27,
      5030: 26,
      5029: 25,
      5028: 24,
      5027: 23,
      5026: 22,
      5025: 22,
      5024: 21,
      5023: 20,
      5022: 19,
      5021: 18,
      5540: 35,
      5539: 35,
      5538: 34,
      5537: 33,
      5536: 32,
      5535: 31,
      5534: 30,
      5533: 29,
      5532: 28,
      5531: 27,
      5530: 27,
      5529: 26,
      5528: 25,
      5527: 24,
      5526: 23,
      5525: 22,
      5524: 21,
      5523: 20,
      5522: 19,
      5521: 19,
      6040: 36,
      6039: 35,
      6038: 35,
      6037: 34,
      6036: 33,
      6035: 32,
      6034: 31,
      6033: 30,
      6032: 29,
      6031: 28,
      6030: 27,
      6029: 26,
      6028: 25,
      6027: 25,
      6026: 24,
      6025: 23,
      6024: 22,
      6023: 21,
      6022: 20,
      6021: 19,
      6540: 37,
      6539: 36,
      6538: 35,
      6537: 35,
      6536: 34,
      6535: 33,
      6534: 32,
      6533: 31,
      6532: 30,
      6531: 29,
      6530: 28,
      6529: 27,
      6528: 26,
      6527: 25,
      6526: 24,
      6525: 23,
      6524: 22,
      6523: 22,
      6522: 21,
      6521: 20,
      7040: 38,
      7039: 37,
      7038: 36,
      7037: 35,
      7036: 34,
      7035: 33,
      7034: 33,
      7033: 32,
      7032: 31,
      7031: 30,
      7030: 29,
      7029: 28,
      7028: 27,
      7027: 26,
      7026: 25,
      7025: 24,
      7024: 23,
      7023: 22,
      7022: 21,
      7021: 20,
      7540: 39,
      7539: 38,
      7538: 37,
      7537: 36,
      7536: 35,
      7535: 34,
      7534: 33,
      7533: 32,
      7532: 31,
      7531: 30,
      7530: 29,
      7529: 29,
      7528: 28,
      7527: 27,
      7526: 26,
      7525: 25,
      7524: 24,
      7523: 23,
      7522: 22,
      7521: 21,
      8040: 40,
      8039: 39,
      8038: 38,
      8037: 37,
      8036: 36,
      8035: 35,
      8034: 34,
      8033: 33,
      8032: 32,
      8031: 31,
      8030: 30,
      8029: 29,
      8028: 28,
      8027: 27,
      8026: 26,
      8025: 25,
      8024: 24,
      8023: 23,
      8022: 22,
      8021: 21,
      8540: 41,
      8539: 40,
      8538: 39,
      8537: 38,
      8536: 37,
      8535: 36,
      8534: 35,
      8533: 34,
      8532: 33,
      8531: 32,
      8530: 31,
      8529: 30,
      8528: 29,
      8527: 28,
      8526: 27,
      8525: 26,
      8524: 25,
      8523: 24,
      8522: 23,
      8521: 22,
      9040: 42,
      9039: 41,
      9038: 40,
      9037: 39,
      9036: 38,
      9035: 37,
      9034: 36,
      9033: 35,
      9032: 34,
      9031: 33,
      9030: 32,
      9029: 31,
      9028: 30,
      9027: 29,
      9026: 28,
      9025: 27,
      9024: 26,
      9023: 25,
      9022: 24,
      9021: 23,
      9540: 43,
      9539: 42,
      9538: 41,
      9537: 40,
      9536: 39,
      9535: 38,
      9534: 37,
      9533: 35,
      9532: 34,
      9531: 33,
      9530: 32,
      9529: 31,
      9528: 30,
      9527: 29,
      9526: 28,
      9525: 27,
      9524: 26,
      9523: 25,
      9522: 24,
      9521: 23,
      10040: 44,
      10039: 43,
      10038: 42,
      10037: 41,
      10036: 39,
      10035: 38,
      10034: 37,
      10033: 36,
      10032: 35,
      10031: 34,
      10030: 33,
      10029: 32,
      10028: 31,
      10027: 30,
      10026: 29,
      10025: 28,
      10024: 27,
      10023: 26,
      10022: 25,
      10021: 24
    }

    var humidity = Math.floor(humidity / 5) * 5;
    var num;

    if (temperature < 21) {
      num = 15;
    } else {
      num = WBGTTable[humidity.toString() + temperature.toString()];
    };

    var ret = "";

    if (num >= 31) {
      ret = isStatus ? this.lang.show("危険") : this.lang.show("運動は原則禁止");
    } else if (28 <= num && num <= 30) {
      ret = isStatus ? this.lang.show("厳重警戒") : this.lang.show("激しい運動は中止");
    } else if (25 <= num && num <= 27) {
      ret = isStatus ? this.lang.show("警戒") : this.lang.show("積極的に休憩");
    } else if (21 <= num && num <= 24) {
      ret = isStatus ? this.lang.show("注意") : this.lang.show("積極的に水分補給");
    } else if (15 <= num && num <= 20) {
      ret = isStatus ? this.lang.show("ほぼ安全") : this.lang.show("適宜水分補給");
    } else {
      ret = isStatus ? this.lang.show("ほぼ安全") : this.lang.show("適宜水分補給");
    }

    this.userInfo.wbgt = num;

    return ret;

  }

  getShimpakuState(isState) {
    if (this.userInfo.datasAnsCar.filter((value) => {
      return value == 2;
    }).length > (60 * 10 / 2)) {
      return isState ? this.lang.show("高心拍") : this.lang.show("10分以上高い心拍数が続いています");
    }

    if (this.userInfo.datasAnsCar.filter((value) => {
      return value == 3;
    }).length > (60 * 10 / 2)) {
      return isState ? this.lang.show("低心拍") : this.lang.show("10分以上低い心拍数が続いています");
    }

    if (this.userInfo.datasNR.filter((value) => {
      return (value == 2 || value == 3);
    }).length > (60 * 10 / 2)) {
      return isState ? this.lang.show("不整脈") : this.lang.show("不整脈の発生回数が多くなっています");
    }

    return this.lang.show("正常")
  }

  getShinpakuIcon() {
    if (this.getShimpakuState(true) === "正常") {
      return true;
    } else {
      return false;
    }
  }

  getJomakuStr(num) {

    var ret = "";

    switch (num) {
      case 0:
        ret = this.lang.show("判定なし");
        break;
      case 1:
        ret = this.lang.show("正常");
        break;
      case 2:
        ret = this.lang.show("高心拍");
        break;
      case 3:
        ret = this.lang.show("低心拍");
        break;
      default:
        ret = this.lang.show("判定なし");
    }

    return ret;

  }








}
