import * as firebase from 'firebase/app';
import { Distinct } from './Distinct';
import { Department } from '../pages/department/department.component';

export class DepartmentRepository {
  static async getDepartmentSubcates(department: Department | null): Promise<any[]> {
    return (await firebase.default.firestore().collection('subcate')
        .where('groupId', '==', department?.id ?? '')
        .get())
        .docs
        .map(doc => doc.data());
  }

  static async getDepartmentSubcatesNogroup(department: Department | null): Promise<any[]> {
    return (await firebase.default.firestore().collection('subcate')
        .get())
        .docs
        .map(doc => doc.data());
  }

  static async getDepartmentWearerIds(department: Department | null): Promise<string[]> {
    return Distinct((await this.getDepartmentSubcates(department)).map(subcate => subcate.id));
  }

  static async getDepartmentDevices(department: Department | null): Promise<any[]> {
    if(department === null) {
        return (await firebase.default.firestore().collection('devices')
        .get())
        .docs
        .map(doc => doc.data());
    }

    const wearerIds = await this.getDepartmentWearerIds(department);

    return (await Promise.all(wearerIds.map(async wearerId => {
        const device = (await firebase.default.firestore().collection('devices')
        .where('wearerId', '==', wearerId)
        .get())
        .docs
        .map(doc => doc.data());
        return device;
    }))).reduce((prev, curr) => prev.concat(curr), []);
  }

  static async getDepartmentManagers(department: Department): Promise<any[]> {
    if(department === null) {
        return (await firebase.default.firestore().collection('managers')
        .get())
        .docs
        .map(doc => doc.data());
    }

    return (await firebase.default.firestore().collection('managers')
    .where('groupId', '==', department.id)
    .get())
    .docs
    .map(doc => doc.data());
  }

  static async readDepartments(): Promise<Department[]> {
    return (await firebase.default.firestore().collection("departments").get()).docs.map(doc => doc.data()) as Department[];
  }
}
