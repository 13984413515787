

import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, first, map } from 'rxjs/operators';
import Swal from 'sweetalert2'

import * as firebase from 'firebase/app';
import { MatTableDataSource } from "@angular/material/table";
import {
  TABLES,
  PeriodicElement,
  Transaction,
  Columns,
} from "../../assets/table-data";
import { Observable } from 'rxjs';
import { AlertViewService } from 'src/app/services/alert-view.service';
import DeviceRepository from 'src/app/repository/DeviceRepository';
import { getLangFromComponent } from 'src/app/lang/logic';
import { ComponentLabel } from 'src/app/lang/dictionary';
import { DepartmentRepository } from 'src/app/repository/DepartmentRepository';
import { SessionService } from 'src/app/service/session.service';
import { Department } from '../department/department.component';


type Element = PeriodicElement | Transaction;
@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  public lang = getLangFromComponent(ComponentLabel.userlist)

  public dataSource: MatTableDataSource<Element> =
    new MatTableDataSource<Element>();

  public columns: Columns[] = [];
  public rowColumns: string[] = [];
  public items: Observable<any[]>;

  dateForView = new Date();
  timeForView = new Date();

  numOfItemSlice = 1000000;

  sortHeaderNum = 0;

  public managers: Observable<any[]>;

  department: Department | null = null;
  departmentId = '';
  departments: Department[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private db: AngularFirestore,
    private alertService: AlertViewService,
    private route: ActivatedRoute,
    private session: SessionService,
  ) {
    this.departmentId = this.activatedRoute.snapshot.paramMap.get('department');
    this.session.checkCorrectDepartment(this.departmentId, 'user-list')
      .then(d => {
        this.department = d;
        this.items = db
          .collection<any>('subcate')
          .snapshotChanges()
          .pipe(
            map(actions => actions.map(action => {
              var data = action.payload.doc.data();

              if (data.phone == undefined) {
                data.phone = "";
              }
              if (data.managerId == undefined) {
                data.managerId = "";
              }
              if (data.id == undefined) {
                data.id = action.payload.doc.id;
              }

              return data;
            }).filter(subcate => this.isTop() || subcate.groupId === this.department.id)
          ));


        this.managers = db
          .collection<any>('managers')
          .snapshotChanges()
          .pipe(
            map(actions => actions.map(action => {
              const data = action.payload.doc.data();

              return data;
            })
          ));
    });
  }

  isTop(): boolean {
    return this.department === null;
  }

  getWorkTime(subcate: any) {
    const { workStartTime, workEndTime } = subcate;
    if(!workStartTime || !workEndTime) {
      return "-";
    }

    return `${workStartTime}-${workEndTime}`;
  }


  checkIsPriority(subcateId) {
    return DeviceRepository.getPrioritySubcateIds().includes(subcateId);
  }

  togglePriority(subcateId) {
    if (this.checkIsPriority(subcateId)) {
      DeviceRepository.removePrioritySubcateId(subcateId);
    } else {
      DeviceRepository.addPrioritySubcateId(subcateId);
    }
  }

  readMore() {
    this.numOfItemSlice = this.numOfItemSlice + 5;
  }

  async editWorkTime(id: string) {
    const cate = (await firebase.default.firestore().collection("subcate").doc(id).get()).data();
    const startTime = cate.workStartTime ?? "";
    const endTime = cate.workEndTime ?? "";

    const { value: formValues } = await Swal.fire({
      title: this.lang.show('業務時間を編集'),
      html:
        `<div>
        <div style="padding-top: 2rem;">${this.lang.show("業務開始時刻")}</div><div><input id="swal-start-time" class="swal2-input" type="time" value="${startTime}"></div>
        <div style="padding-top: 2rem;">${this.lang.show("業務終了時刻")}</div><div><input id="swal-end-time" class="swal2-input" type="time" value="${endTime}"></div>
        </div>`,
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: async () => {
        const getValueById = (id: string): string => document.getElementById(id)["value"];
        const workStartTime = getValueById('swal-start-time');
        const workEndTime = getValueById('swal-end-time');

        if(!workStartTime || !workEndTime) {
          return null;
        }

        const data = {
          workStartTime,
          workEndTime,
        };

        return data;
      },
    });

    if (formValues) {
      firebase.default.firestore().collection("subcate").doc(id).update(formValues);
      Swal.fire(this.lang.show("更新を完了しました"));
    }
  }

  deleteUser(id, name) {
    this.alertService.showYesNoAlertDelete(name).then((ret) => {
      if (ret) {
        firebase.default.firestore().collection("subcate").doc(id).delete();
      }
    })

  }

  showDepartmentFromId(subcate: any): string {
    const dep = this.departments.find(d => d.id === subcate.groupId);
    return dep?.name ?? "";
  }

  async editDepartment(subcate: any) {
    const departmentListStr = (departmentId: string): string => {
      let html = `<option value="">${this.lang.show("選択なし")}</option>`
      this.departments.forEach(d => {
        html = html + `<option value="${d.id}" ${(departmentId && d.id === departmentId ? 'selected' : '')}>${d.name}</option>`;
      });

      return html;
    };

    const { value: formValues } = await Swal.fire({
      title: this.lang.show('部署の編集'),
      html:
        `<div style="display: grid; grid-template-columns: 5rem 1fr;">
        <div style="padding-top: 2rem;">${this.lang.show("部署")}</div><div><select id="swal-input31" class="swal2-input" style="width: -webkit-fill-available;">${departmentListStr(subcate?.groupId ?? '')}</select></div>
        </div>`,
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        const getValueById = (id: string): string => document.getElementById(id)["value"];

        const data = {
          groupId: getValueById('swal-input31'),
        };

        return data;
      },
    });

    if (formValues) {
      firebase.default.firestore().collection("subcate").doc(subcate.id).update(formValues);
      Swal.fire(this.lang.show("更新を完了しました"));
    }
  }

  async showAddUserAlert(id, name, phone, group, workStartTime, workEndTime, managerId, managerId2, managerId3, managerId4, managerId5, managerId6, managerId7, managerId8, managerId9, managerId10, managers) {
    const depaertmentManagers = await DepartmentRepository.getDepartmentManagers(this.department);


    const optionsListStr = (selectedId: string): string => {
      let html = `<option value="${this.lang.show("選択なし")}">`
      depaertmentManagers.forEach(manager => {
        html = html + '<option value="' + manager.id + '"' + (selectedId && manager.id === selectedId ? 'selected' : '') + '>' + manager.name + '</option>';
      });

      return html;
    }

    const { value: formValues } = await Swal.fire({
      title: this.lang.show('ユーザ情報を登録'),
      html:
        `<div style="display: grid; grid-template-columns: 5rem 1fr;">
        <div style="padding-top: 2rem;">${this.lang.show("名前")}</div><div><input id="swal-input1" class="swal2-input" value="${name}"></div>
        <div style="padding-top: 2rem;">${this.lang.show("電話番号")}</div><div><input id="swal-input2" class="swal2-input" value="${phone}"></div>
        <div style="padding-top: 2rem;">${this.lang.show("業務開始時刻")}</div><div><input id="swal-start-time" class="swal2-input" type="time" value="${workStartTime ?? ""}"></div>
        <div style="padding-top: 2rem;">${this.lang.show("業務終了時刻")}</div><div><input id="swal-end-time" class="swal2-input" type="time" value="${workEndTime ?? ""}"></div>
        <div style="padding-top: 2rem;">${this.lang.show("管理者")}1</div><div><select id="swal-input31" class="swal2-input" style="width: -webkit-fill-available;">${optionsListStr(managerId)}</select></div>
        <div style="padding-top: 2rem;">${this.lang.show("管理者")}2</div><div><select id="swal-input32" class="swal2-input" style="width: -webkit-fill-available;">${optionsListStr(managerId2)}</select></div>
        <div style="padding-top: 2rem;">${this.lang.show("管理者")}3</div><div><select id="swal-input33" class="swal2-input" style="width: -webkit-fill-available;">${optionsListStr(managerId3)}</select></div>
        <div style="padding-top: 2rem;">${this.lang.show("管理者")}4</div><div><select id="swal-input34" class="swal2-input" style="width: -webkit-fill-available;">${optionsListStr(managerId4)}</select></div>
        <div style="padding-top: 2rem;">${this.lang.show("管理者")}5</div><div><select id="swal-input35" class="swal2-input" style="width: -webkit-fill-available;">${optionsListStr(managerId5)}</select></div>
        <div style="padding-top: 2rem;">${this.lang.show("管理者")}6</div><div><select id="swal-input36" class="swal2-input" style="width: -webkit-fill-available;">${optionsListStr(managerId6)}</select></div>
        <div style="padding-top: 2rem;">${this.lang.show("管理者")}7</div><div><select id="swal-input37" class="swal2-input" style="width: -webkit-fill-available;">${optionsListStr(managerId7)}</select></div>
        <div style="padding-top: 2rem;">${this.lang.show("管理者")}8</div><div><select id="swal-input38" class="swal2-input" style="width: -webkit-fill-available;">${optionsListStr(managerId8)}</select></div>
        <div style="padding-top: 2rem;">${this.lang.show("管理者")}9</div><div><select id="swal-input39" class="swal2-input" style="width: -webkit-fill-available;">${optionsListStr(managerId9)}</select></div>
        <div style="padding-top: 2rem;">${this.lang.show("管理者")}10</div><div><select id="swal-input40" class="swal2-input" style="width: -webkit-fill-available;">${optionsListStr(managerId10)}</select></div>'
        </div>`,
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        const getValueById = (id: string): string => document.getElementById(id)["value"];
        const workStartTime = getValueById('swal-start-time');
        const workEndTime = getValueById('swal-end-time');

        const data = {
          name: getValueById('swal-input1'),
          phone: getValueById('swal-input2'),
          managerId: getValueById('swal-input31'),
          managerId2: getValueById('swal-input32'),
          managerId3: getValueById('swal-input33'),
          managerId4: getValueById('swal-input34'),
          managerId5: getValueById('swal-input35'),
          managerId6: getValueById('swal-input36'),
          managerId7: getValueById('swal-input37'),
          managerId8: getValueById('swal-input38'),
          managerId9: getValueById('swal-input39'),
          managerId10: getValueById('swal-input40'),
          workStartTime,
          workEndTime,
        };

        if (data.name.length === 0) {
          alert(this.lang.show("名前の項目が未入力です"));
          return false;
        } else if (data.phone.length === 0) {
          alert(this.lang.show("電話番号の項目が未入力です"));
          return false;
        }

        return data;
      },
    });

    if (id === undefined) {
      if (formValues) {
        const newId = firebase.default.firestore().collection("subcate").doc().id;
        formValues["id"] = newId;
        firebase.default.firestore().collection("subcate").doc(newId).set(formValues);
        Swal.fire(this.lang.show("登録を完了しました"));
      }
    } else {
      if (formValues) {
        firebase.default.firestore().collection("subcate").doc(id).update(formValues);
        Swal.fire(this.lang.show("更新を完了しました"));
      }
    }

  }

  async editGroup(subcate: any) {
    const { value: formValues } = await Swal.fire({
      title: this.lang.show('部署の編集'),
      html:
        `<div style="display: grid; grid-template-columns: 5rem 1fr;">
        <div style="padding-top: 2rem;">${this.lang.show("名前")}</div><div><input id="swal-input1" class="swal2-input" value="${subcate.name}" readonly></div>
        <div style="padding-top: 2rem;">${this.lang.show("所属")}</div><div><input id="swal-input21" class="swal2-input" value="${subcate.group}"></div>
        </div>`,
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        const getValueById = (id: string): string => document.getElementById(id)["value"];

        const data = {
          group: getValueById('swal-input21'),
        };

        return data;
      },
    });

    if (formValues) {
      firebase.default.firestore().collection("subcate").doc(subcate.id).update(formValues);
      Swal.fire(this.lang.show("更新を完了しました"));
    }
  }

  async editManager(subcate: any) {
    const myManagers = await DepartmentRepository.getDepartmentManagers(subcate.group);
    const { managerId, managerId2, managerId3, managerId4, managerId5, managerId6, managerId7, managerId8, managerId9, managerId10 } = subcate;

    const optionsListStr = (selectedId: string): string => {
      let html = `<option value="">${this.lang.show("選択なし")}</option>`
      myManagers.forEach(manager => {
        html = html + `<option value="${manager.id}" ${(selectedId && manager.id === selectedId ? 'selected' : '')}>${manager.name}</option>`;
      });

      return html;
    };

    const { value: formValues } = await Swal.fire({
      title: this.lang.show('管理者の編集'),
      html:
        `<div style="display: grid; grid-template-columns: 5rem 1fr;">
        <div style="padding-top: 2rem;">${this.lang.show("名前")}</div><div><input id="swal-input1" class="swal2-input" value="${subcate.name}" readonly></div>
        <div style="padding-top: 2rem;">${this.lang.show("管理者")}1</div><div><select id="swal-input31" class="swal2-input" style="width: -webkit-fill-available;">${optionsListStr(managerId)}</select></div>
        <div style="padding-top: 2rem;">${this.lang.show("管理者")}2</div><div><select id="swal-input32" class="swal2-input" style="width: -webkit-fill-available;">${optionsListStr(managerId2)}</select></div>
        <div style="padding-top: 2rem;">${this.lang.show("管理者")}3</div><div><select id="swal-input33" class="swal2-input" style="width: -webkit-fill-available;">${optionsListStr(managerId3)}</select></div>
        <div style="padding-top: 2rem;">${this.lang.show("管理者")}4</div><div><select id="swal-input34" class="swal2-input" style="width: -webkit-fill-available;">${optionsListStr(managerId4)}</select></div>
        <div style="padding-top: 2rem;">${this.lang.show("管理者")}5</div><div><select id="swal-input35" class="swal2-input" style="width: -webkit-fill-available;">${optionsListStr(managerId5)}</select></div>
        <div style="padding-top: 2rem;">${this.lang.show("管理者")}6</div><div><select id="swal-input36" class="swal2-input" style="width: -webkit-fill-available;">${optionsListStr(managerId6)}</select></div>
        <div style="padding-top: 2rem;">${this.lang.show("管理者")}7</div><div><select id="swal-input37" class="swal2-input" style="width: -webkit-fill-available;">${optionsListStr(managerId7)}</select></div>
        <div style="padding-top: 2rem;">${this.lang.show("管理者")}8</div><div><select id="swal-input38" class="swal2-input" style="width: -webkit-fill-available;">${optionsListStr(managerId8)}</select></div>
        <div style="padding-top: 2rem;">${this.lang.show("管理者")}9</div><div><select id="swal-input39" class="swal2-input" style="width: -webkit-fill-available;">${optionsListStr(managerId9)}</select></div>
        <div style="padding-top: 2rem;">${this.lang.show("管理者")}10</div><div><select id="swal-input40" class="swal2-input" style="width: -webkit-fill-available;">${optionsListStr(managerId10)}</select></div>
        </div>`,
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        const getValueById = (id: string): string => document.getElementById(id)["value"];

        const data = {
          managerId: getValueById('swal-input31'),
          managerId2: getValueById('swal-input32'),
          managerId3: getValueById('swal-input33'),
          managerId4: getValueById('swal-input34'),
          managerId5: getValueById('swal-input35'),
          managerId6: getValueById('swal-input36'),
          managerId7: getValueById('swal-input37'),
          managerId8: getValueById('swal-input38'),
          managerId9: getValueById('swal-input39'),
          managerId10: getValueById('swal-input40'),
        };

        return data;
      },
    });

    if (formValues) {
      firebase.default.firestore().collection("subcate").doc(subcate.id).update(formValues);
      Swal.fire(this.lang.show("更新を完了しました"));
    }
  }

  getDateFormated(str) {
    if (str == null) return ""
    return str.split(":")[0];
  }

  getManagerById(id, managers) {
    return managers.find((manager) => {
      return manager.id === id;
    })
  }

  getManagerNamesByItem(item, managers): string {
    const managerIds = [item.managerId, item['managerId2'], item['managerId3'], item['managerId4'], item['managerId5'], item['managerId6'], item['managerId7'], item['managerId8'], item['managerId9'], item['managerId10']];
    const mngrs = managerIds.map(managerId => this.getManagerById(managerId, managers)).filter(x => x);

    console.log({ managerIds, mngrs, managers });
    return mngrs.map(manager => manager.name).join(', ');
  }

  getTimeFormated(str) {
    if (str == null) return "";
    return str.split(":")[1] + ":" + str.split(":")[2] + ":" + str.split(":")[3];
  }

  showAlert(str) {
    alert(str);
  };

  formatDate(date, format) {
    format = format.replace(/yyyy/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3));
    return format;
  };

  sliceMacAdress(ma) {
    var ret = ma.replace(':', '').replace(':', '').replace(':', '').replace(':', '').replace(':', '').replace(':', '').replace(':', '')

    return ret.slice(-4);
  }

  checkOnGoingState(relatedData) {
    if (relatedData[0] == undefined) return false;

    const itemDate = new Date(relatedData[0].timestamp.seconds * 1000).getTime();
    const currentDate = new Date().getTime();

    var difference = currentDate - itemDate;
    if (difference > 5000) {
      return false;
    } else {
      return true;
    }

  }

  async readDepartments() {
    this.departments = await DepartmentRepository.readDepartments();
  }

  ngOnInit(): void {
    this.readDepartments();
    this.activatedRoute.data.subscribe((data) => {
      const tableName = data.tableName as keyof typeof TABLES;
      const table = TABLES[tableName];

      this.dataSource = new MatTableDataSource<Element>(table.data);
      this.columns = table.columns;
      this.rowColumns = this.columns.map((c) => c.def);
    });
  }
}