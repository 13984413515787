import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { ActivatedRoute, Router } from "@angular/router";
import { filter, first, map } from "rxjs/operators";
import Swal from "sweetalert2";

import * as firebase from "firebase/app";
import { MatTableDataSource } from "@angular/material/table";
import {
  TABLES,
  PeriodicElement,
  Transaction,
  Columns,
} from "../../assets/table-data";
import { Observable } from "rxjs";
import { AlertViewService } from "src/app/services/alert-view.service";
import { getLangFromComponent } from "src/app/lang/logic";
import { ComponentLabel } from "src/app/lang/dictionary";
import { SessionService } from "src/app/service/session.service";
import { DepartmentRepository } from "src/app/repository/DepartmentRepository";
import { Department } from "../department/department.component";

export interface AlertItem {
  name: string;
  managerName: string;
  deviceNumber: string;
  time: Date;
  category: string;
}

type Element = PeriodicElement | Transaction;
@Component({
  selector: "app-alert-list",
  templateUrl: "./alert-list.component.html",
  styleUrls: ["./alert-list.component.css"],
})
export class AlertListComponent implements OnInit {
  lang = getLangFromComponent(ComponentLabel.alertlist)
  public dataSource: MatTableDataSource<Element> =
    new MatTableDataSource<Element>();

  public columns: Columns[] = [];
  public rowColumns: string[] = [];
  public items: any[];
  public alertItems: AlertItem[] = [];
  public filteredItems: AlertItem[] = [];

  dateForView = new Date();
  timeForView = new Date();

  numOfItemSlice = 1000000;

  sortHeaderNum = 0;

  public managers: any[];
  public searchText = '';

  public displayCount = 20;
  private _displayCountAdd = 20;
  department: Department | null = null;
departmentId = '';


  constructor(
    private activatedRoute: ActivatedRoute,
    private db: AngularFirestore,
    private alertService: AlertViewService,
    private route: ActivatedRoute,
    private session: SessionService
  ) {
    this.departmentId = this.activatedRoute.snapshot.paramMap.get('department');
    this.session.checkCorrectDepartment(this.departmentId, 'alert-list').then(d => {
      this.department = d
      DepartmentRepository.getDepartmentSubcates(this.department)
      .then(datas => this.items = datas.map(data => {
        if (data.phone == undefined) {
          data.phone = "";
        }
        if (data.managerId == undefined) {
          data.managerId = "";
        }

        return data;
      }));
    
      DepartmentRepository.getDepartmentManagers(this.department).then(datas => this.managers = datas);

      this.getDeviceAlerts();
    });
  }

  addDisplayCount() {
    this.displayCount += this._displayCountAdd;
  }

  searchWord() {
    this.filteredItems = this.alertItems.filter(item => {
      return !this.searchText ||
      item.name.includes(this.searchText) ||
      item.deviceNumber.includes(this.searchText) ||
      item.category.includes(this.searchText);
    }).sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime());
  }

  getManagerNamesByItem(item, managers): string {
    const managerNames = [item.managerId, item['managerId2'], item['managerId3'], item['managerId4'], item['managerId5'], item['managerId6'], item['managerId7'], item['managerId8'], item['managerId9'], item['managerId10']].map(managerId => this.getManagerById(managerId, managers)).filter(x => x).map(manager => manager.name);

    if(managerNames.length > 0) {
      // console.log({ managerCount: managerNames.length, managerNames });
    }
    return managerNames.join(', ');
  }


  async getDeviceAlerts(): Promise<void> {
    const db = firebase.default.firestore();
    const devices = await DepartmentRepository.getDepartmentDevices(this.department);
    const cateCollection = db.collection('subcate');

    const existHistoryDevices = devices.filter(device => device.alertsHistory && device.alertsHistory.length > 0);

    this.alertItems = await this.getAlerts(existHistoryDevices, cateCollection, db);
    this.filteredItems = this.alertItems;
  }

  async getAlerts(existHistoryDevices: any[], cateCollection: any, db: any): Promise<AlertItem[]> {
    const cates = (await cateCollection.get()).docs.map(doc => doc.data());
    const managers = (await db.collection('managers').get()).docs.map(doc => doc.data());

    let docs: AlertItem[] = [];

    existHistoryDevices.filter(item => {
      if (item.wearerId === undefined) { return false; }
      if (item.wearerId === '') { return false; }


      return true;

    }).forEach((item: any) => {
      item.alertsHistory.forEach(alert => {
        const [time, category] = alert.split('_');
        const deviceNumber = item.id;
        const wearerId = item?.wearerId ?? '';



        const scate = cates.find(c => c?.id === wearerId);
        const name = scate?.name ?? '';

        const managerId = scate?.managerId;
        const manager = managers.find(m => m?.id === managerId);
        const managerName = manager?.name ?? '';

        const doc: AlertItem = {
          ...scate,
          name,
          managerName,
          time: new Date(time).toLocaleString(this.lang.show('ja')),
          category,
          deviceNumber,
        };

        docs.push(doc);
      });
    });

    return docs.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime());
  }

  readMore() {
    this.numOfItemSlice = this.numOfItemSlice + 5;
  }

  deleteUser(id) {
    this.alertService.showYesNoAlertDelete("ユーザ情報").then((ret) => {
      if (ret) {
        firebase.default.firestore().collection("subcate").doc(id).delete();
      }
    });
  }

  async showAddUserAlert(id, name, phone, managerId, managers) {
    var optionsListStr = '<option value="選択なし">';
    managers.forEach((manager) => {
      optionsListStr =
        optionsListStr +
        '<option value="' +
        manager.id +
        '">' +
        manager.name +
        "</option>";
    });

    const { value: formValues } = await Swal.fire({
      title: "ユーザ情報を登録",
      html:
        '名前　　：<input id="swal-input1" class="swal2-input" value="' +
        name +
        '">' +
        '電話番号：<input id="swal-input2" class="swal2-input" value="' +
        phone +
        '">' +
        '管理者　：<select id="swal-input3" class="swal2-input">' +
        optionsListStr +
        "</select>",
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        const data = {
          name: document.getElementById("swal-input1")["value"],
          phone: document.getElementById("swal-input2")["value"],
          managerId: document.getElementById("swal-input3")["value"],
        };

        if (data.name.length == 0) {
          alert("名前の項目が未入力です");
          return false;
        } else if (data.phone.length == 0) {
          alert("電話番号の項目が未入力です");
          return false;
        }

        return data;
      },
    });

    if (id == undefined) {
      if (formValues) {
        const newId = firebase.default
          .firestore()
          .collection("subcate")
          .doc().id;
        formValues["id"] = newId;
        firebase.default
          .firestore()
          .collection("subcate")
          .doc(newId)
          .set(formValues);
        Swal.fire("登録を完了しました");
      }
    } else {
      if (formValues) {
        firebase.default
          .firestore()
          .collection("subcate")
          .doc(id)
          .update(formValues);
        Swal.fire("更新を完了しました");
      }
    }
  }

  getDateFormated(str) {
    if (str == null) return ""
    return str.split(":")[0];
  }

  getManagerById(id, managers) {
    return managers.find((manager) => {
      return manager.id === id;
    });
  }

  getTimeFormated(str) {
    if (str == null) return "";
    return (
      str.split(":")[1] + ":" + str.split(":")[2] + ":" + str.split(":")[3]
    );
  }

  showAlert(str) {
    alert(str);
  }

  formatDate(date, format) {
    format = format.replace(/yyyy/g, date.getFullYear());
    format = format.replace(/MM/g, ("0" + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/dd/g, ("0" + date.getDate()).slice(-2));
    format = format.replace(/HH/g, ("0" + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ("0" + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ("0" + date.getSeconds()).slice(-2));
    format = format.replace(/SSS/g, ("00" + date.getMilliseconds()).slice(-3));
    return format;
  }

  sliceMacAdress(ma) {
    var ret = ma
      .replace(":", "")
      .replace(":", "")
      .replace(":", "")
      .replace(":", "")
      .replace(":", "")
      .replace(":", "")
      .replace(":", "");

    return ret.slice(-4);
  }

  checkOnGoingState(relatedData) {
    if (relatedData[0] == undefined) return false;

    const itemDate = new Date(
      relatedData[0].timestamp.seconds * 1000
    ).getTime();
    const currentDate = new Date().getTime();

    var difference = currentDate - itemDate;
    if (difference > 5000) {
      return false;
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data) => {
      const tableName = data.tableName as keyof typeof TABLES;
      const table = TABLES[tableName];

      this.dataSource = new MatTableDataSource<Element>(table.data);
      this.columns = table.columns;
      this.rowColumns = this.columns.map((c) => c.def);
    });
  }
}
