

import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, first, map } from 'rxjs/operators';
import Swal from 'sweetalert2';

import * as firebase from 'firebase/app';
import { MatTableDataSource } from "@angular/material/table";
import {
  TABLES,
  PeriodicElement,
  Transaction,
  Columns,
} from "../../assets/table-data";
import { Observable } from 'rxjs';
import { AlertViewService } from 'src/app/services/alert-view.service';
import { SessionService } from 'src/app/service/session.service';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/class/user/user';
import { AngularFireAuth } from '@angular/fire/auth';
import { Distinct } from 'src/app/repository/Distinct';
import { getLangFromComponent, getLanguage } from 'src/app/lang/logic';
import { ComponentLabel } from 'src/app/lang/dictionary';
import { Department } from '../department/department.component';
import { DepartmentRepository } from 'src/app/repository/DepartmentRepository';


interface subCate {
  id: string,
  name: string,
  managerIds: string[]
}

const managerFields = [
  'managerId',
  ...[...Array(9)].map((_, i) => `managerId${i + 2}`),
]

type Element = PeriodicElement | Transaction;
@Component({
  selector: 'app-manager-list',
  templateUrl: './manager-list.component.html',
  styleUrls: ['./manager-list.component.css']
})
export class ManagerListComponent implements OnInit {
  public lang = getLangFromComponent(ComponentLabel.managerlist)

  public dataSource: MatTableDataSource<Element> =
    new MatTableDataSource<Element>();

  public columns: Columns[] = [];
  public rowColumns: string[] = [];
  public items: Observable<any[]>;

  dateForView = new Date();
  timeForView = new Date();

  numOfItemSlice = 1000000;

  sortHeaderNum = 0;

  public managers: Observable<any[]>;
  public currentUser: User;
  public isMaster = false;
  subcates = [];

  department: Department | null = null;
departmentId = '';
  departments: Department[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private db: AngularFirestore,
    private session: SessionService,
    private afAuth: AngularFireAuth,
    private alertService: AlertViewService,
    private route: ActivatedRoute,
  ) {
    this.departmentId = this.activatedRoute.snapshot.paramMap.get('department');
    this.session.checkCorrectDepartment(this.departmentId, 'manager-list').then(d => {
      this.department = d;
      this.currentUser = this.session.session.user;

    this.afAuth.currentUser.then(async user => {
      const snapShot = await firebase.default.firestore().collection("managers").where("uid", "==", user.uid).get();
      this.isMaster = snapShot.docs.length === 0;
    });

    this.initialize();

    this.managers = db
      .collection<any>('managers')
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(async action => {
          const data = action.payload.doc.data();

          return data;
        })
        ));
    });
  }

  isTop(): boolean {
    return this.department === null;
  }

  async readDeparrtments() {
    this.departments = await DepartmentRepository.readDepartments();
  }

  async initialize() {
    await this.readDeparrtments();
    this.subcates = (await firebase.default.firestore().collection('subcate').get()).docs.map(doc => doc.data());

    this.items = this.db
      .collection<any>('managers')
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(action => {
          var data = action.payload.doc.data();

          if (data.phone == undefined) {
            data.phone = "";
          }
          if (data.email == undefined) {
            data.email = "";
          }
          if (data.id == undefined) {
            data.id = action.payload.doc.id;
          }

          data.subcates = this.subcates.filter(subcate => managerFields.some(field => subcate[field] === data.id));

          console.log({ subcate: data.subcates });

          return data;
        }).filter(data => this.isTop() || data.groupId === this.department.id)
      ));
  }

  getUserNames(manager: any): string {
    return manager.subcates?.map(cate => cate.name).join(', ') ?? '';
  }


  readMore() {
    this.numOfItemSlice = this.numOfItemSlice + 5;
  }

  deleteUser(id, name) {
    console.log("deleteUser")
    this.alertService.showYesNoAlertDelete(name).then((ret) => {
      if (ret) {
        firebase.default.firestore().collection("managers").doc(id).delete();
      }
    })

  }

  async showAddUserAlert(id, name, phone, group, email) {
    const manager = id ? (await firebase.default.firestore().collection("managers").doc(id).get()).data() : null;
    const selectedSubcates = manager ? this.subcates.filter(subcate => managerFields.some(field => subcate[field] === manager.id)) : managerFields.map(x => null);

    const subcates = this.subcates;
    const optionsListStr = (selectedId: string): string => {
      let html = `<option value="${this.lang.show("選択なし")}">`;
      subcates.forEach(cate => {
        html = html + '<option value="' + cate.id + '"' + (selectedId && cate.id === selectedId ? 'selected' : '') + '>' + cate.name + '</option>';
      });

      return html;
    }

    const getUserSelectRow = (index: number) => {
      return `<div style="padding-top: 2rem;">${this.lang.show("ユーザー")}${index + 1}</div><div><select id="swal-user${index}" class="swal2-input" style="width: -webkit-fill-available;">` + optionsListStr(selectedSubcates[index]?.id ?? '') + '</select></div>'
    }

    let userIds = [];

    const { value: formValues } = await Swal.fire({
      title: this.lang.show('ユーザ情報を登録'),
      html:
        '<div style="display: grid; grid-template-columns: 5rem 1fr;">' +
        `<div style="padding-top: 2rem;">${this.lang.show("名前")}</div><div><input id="swal-input1" class="swal2-input" value="${name}"></div>` +
        `<div style="padding-top: 2rem;">${this.lang.show("電話番号")}</div><div><input id="swal-input2" class="swal2-input" value="${phone}"></div>` +
        `<div style="padding-top: 2rem;">${this.lang.show("メール")}</div><div><input id="swal-input3" class="swal2-input" value="${email}"></div>` +
        [...Array(10)].map((_, i) => getUserSelectRow(i)).join('') +
        '</div>',
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: async () => {
        let data = {
          name: document.getElementById('swal-input1')["value"],
          phone: document.getElementById('swal-input2')["value"],
          email: document.getElementById('swal-input3')["value"],
          initialLanguage: getLanguage(),
        };

        userIds = Distinct(managerFields.map((field, i) => document.getElementById(`swal-user${i}`)["value"]))
          .filter(x => x && x !== this.lang.show('選択なし'));

        console.log({ userIds });

        if (data.name.length == 0) {
          alert(this.lang.show("名前の項目が未入力です"));
          return false;
        } else if (data.phone.length == 0) {
          alert(this.lang.show("電話番号の項目が未入力です"));
          return false;
        } else if (data.email.length == 0) {
          alert(this.lang.show("Emailの項目が未入力です"));
          return false;
        }

        const existManager = (await firebase.default.firestore().collection('managers').where('email', '==', data.email).get()).docs[0];
        if (existManager && existManager.id !== id) {
          alert(this.lang.show("既に使用されているEメールアドレスです"));
          return false;
        }

        return data;
      },

    })

    console.log({ formValues, userIds });

    const registerSubcates = async (managerId: string) => {
      const deleteSubcates = selectedSubcates.filter(subcate => subcate && !userIds.includes(subcate.id));
      console.log({ deleteSubcates })

      await Promise.all(deleteSubcates.map(async subcate => {
        const registeredManagers = managerFields.map(field => subcate[field]).filter(x => x);
        const newManagers = registeredManagers.filter(x => x !== managerId);

        managerFields.forEach((field, i) => {
          subcate[field] = newManagers[i] ?? '';
        });

        await firebase.default.firestore().collection('subcate').doc(subcate.id).set(subcate, { merge: true });
      }));

      await Promise.all(userIds.map(async userId => {
        const user = await firebase.default.firestore().collection('subcate').doc(userId).get();
        let data = user.data();

        const registeredManagers = managerFields.map(field => data[field]).filter(x => x);
        const newManagers = Distinct([...registeredManagers, managerId]);
        console.log({ newManagers })

        managerFields.forEach((field, i) => {
          data[field] = newManagers[i] ?? '';
        });

        await user.ref.set(data, { merge: true });
      }));
    }

    if (id == undefined) {
      if (formValues) {
        const newId = firebase.default.firestore().collection("managers").doc().id;
        formValues["id"] = newId;
        firebase.default.firestore().collection("managers").doc(newId).set(formValues, { merge: true });
        await registerSubcates(newId);
        this.initialize();
        Swal.fire(this.lang.show("登録を完了しました"));
      }
    } else {
      if (formValues) {
        firebase.default.firestore().collection("managers").doc(id).update(formValues);
        await registerSubcates(id);
        this.initialize();
        Swal.fire(this.lang.show("更新を完了しました"));
      }
    }

  }

  showDepartmentFromId(manager: any): string {
    const dep = this.departments.find(d => d.id === manager.groupId);
    return dep?.name ?? "";
  }

  async editGroup(item: any) {
    const { id, name, group } = item;

    const departmentListStr = (departmentId: string): string => {
      let html = `<option value="">${this.lang.show("選択なし")}</option>`
      this.departments.forEach(d => {
        html = html + `<option value="${d.id}" ${(departmentId && d.id === departmentId ? 'selected' : '')}>${d.name}</option>`;
      });

      return html;
    };

    const { value: formValues } = await Swal.fire({
      title: this.lang.show('ユーザ情報を登録'),
      html:
        '<div style="display: grid; grid-template-columns: 5rem 1fr;">' +
        `<div style="padding-top: 2rem;">${this.lang.show("名前")}</div><div><input id="swal-input1" class="swal2-input" value="${name}" readonly></div>` +
        `<div style="padding-top: 2rem;">${this.lang.show("部署")}</div><div><select id="swal-input31" class="swal2-input" style="width: -webkit-fill-available;">${departmentListStr(item?.groupId ?? '')}</select></div>` +
        '</div>',
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: async () => {
        let data = {
          groupId: document.getElementById('swal-input31')["value"],
        };

        return data;
      },

    })

    console.log({ formValues });

    
    if (formValues) {
      firebase.default.firestore().collection("managers").doc(id).update(formValues);
      this.initialize();
      Swal.fire(this.lang.show("更新を完了しました"));
    }
  }

  async editUsers(item: any) {
    const { id, name, phone, group, email } = item;
    const manager = id ? (await firebase.default.firestore().collection("managers").doc(id).get()).data() : null;
    const selectedSubcates = manager ? this.subcates.filter(subcate => managerFields.some(field => subcate[field] === manager.id)) : managerFields.map(x => null);

    const subcates = this.subcates.filter(x => x.group === group);
    const optionsListStr = (selectedId: string): string => {
      let html = `<option value="${this.lang.show("選択なし")}">${this.lang.show("選択なし")}</option>`;
      subcates.forEach(cate => {
        html = html + '<option value="' + cate.id + '"' + (selectedId && cate.id === selectedId ? 'selected' : '') + '>' + cate.name + '</option>';
      });

      return html;
    }

    const getUserSelectRow = (index: number) => {
      return `<div style="padding-top: 2rem;">${this.lang.show("ユーザー")}${index + 1}</div><div><select id="swal-user${index}" class="swal2-input" style="width: -webkit-fill-available;">` + optionsListStr(selectedSubcates[index]?.id ?? '') + '</select></div>'
    }

    let userIds = [];

    const { value: formValues } = await Swal.fire({
      title: this.lang.show('ユーザ情報を登録'),
      html:
        '<div style="display: grid; grid-template-columns: 5rem 1fr;">' +
        `<div style="padding-top: 2rem;">${this.lang.show("名前")}</div><div><input id="swal-input1" class="swal2-input" value="${name}" readonly></div>` +
        [...Array(10)].map((_, i) => getUserSelectRow(i)).join('') +
        '</div>',
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: async () => {
        let data = {
          name: document.getElementById('swal-input1')["value"],
        };

        userIds = Distinct(managerFields.map((field, i) => document.getElementById(`swal-user${i}`)["value"]))
          .filter(x => x && x !== this.lang.show('選択なし'));

        console.log({ userIds });

        return data;
      },

    })

    console.log({ formValues, userIds });

    const registerSubcates = async (managerId: string) => {
      const deleteSubcates = selectedSubcates.filter(subcate => subcate && !userIds.includes(subcate.id));
      console.log({ deleteSubcates })

      await Promise.all(deleteSubcates.map(async subcate => {
        const registeredManagers = managerFields.map(field => subcate[field]).filter(x => x);
        const newManagers = registeredManagers.filter(x => x !== managerId);

        managerFields.forEach((field, i) => {
          subcate[field] = newManagers[i] ?? '';
        });

        await firebase.default.firestore().collection('subcate').doc(subcate.id).set(subcate, { merge: true });
      }));

      await Promise.all(userIds.map(async userId => {
        const user = await firebase.default.firestore().collection('subcate').doc(userId).get();
        let data = user.data();

        const registeredManagers = managerFields.map(field => data[field]).filter(x => x);
        const newManagers = Distinct([...registeredManagers, managerId]);
        console.log({ newManagers })

        managerFields.forEach((field, i) => {
          data[field] = newManagers[i] ?? '';
        });

        await user.ref.set(data, { merge: true });
      }));
    }

    if (formValues) {
      firebase.default.firestore().collection("managers").doc(id).update(formValues);
      await registerSubcates(id);
      this.initialize();
      Swal.fire(this.lang.show("更新を完了しました"));
    }
  }

  getDateFormated(str) {
    console.log(str);
    if (str == null) return ""
    return str.split(":")[0];
  }

  getTimeFormated(str) {
    console.log(str);
    if (str == null) return "";
    return str.split(":")[1] + ":" + str.split(":")[2] + ":" + str.split(":")[3];
  }

  showAlert(str) {
    alert(str);
  };

  formatDate(date, format) {
    format = format.replace(/yyyy/g, date.getFullYear());
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3));
    return format;
  };

  sliceMacAdress(ma) {
    var ret = ma.replace(':', '').replace(':', '').replace(':', '').replace(':', '').replace(':', '').replace(':', '').replace(':', '')

    return ret.slice(-4);
  }

  checkOnGoingState(relatedData) {
    if (relatedData[0] == undefined) return false;

    const itemDate = new Date(relatedData[0].timestamp.seconds * 1000).getTime();
    const currentDate = new Date().getTime();

    var difference = currentDate - itemDate;
    if (difference > 5000) {
      return false;
    } else {
      return true;
    }

  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data) => {
      const tableName = data.tableName as keyof typeof TABLES;
      const table = TABLES[tableName];

      this.dataSource = new MatTableDataSource<Element>(table.data);
      this.columns = table.columns;
      this.rowColumns = this.columns.map((c) => c.def);
    });
  }
}